import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SurveyService } from './services/survey.service';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { ConfigService } from './services/config.service';
import { ApiService } from './services/api.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
    ],
    declarations: [],
    providers: [
        ConfigService,
        ApiService,
        SurveyService
    ]
})
/**
 * CoreModule may only be loaded once!
 */
export class CoreModule {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        // Checks if CoreModule is only loaded once
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
