import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
    constructor() { }

    getApiUri(): string {
        throw Error('Api uri not set! Please set this in the ConfigService!');
    }
}
