export enum PageItemType {
    Question = 'Question'
}

export interface Survey {
    uuid: string;
    name: string;
    pages?: Page[];
    role: Role;
}

export enum Role {
    Administrator = 'Administrator',
    Developer = 'Developer'
}

export interface Page {
    uuid: string;
    step: number;
    name: string;
    items: PageItem[];
}

export interface PageItem {
    type: PageItemType;
}

export interface PageItemQuestion extends PageItem {
    question: Question;
}

export enum QuestionType {
    Slider = 'Slider',
    MultipleChoice = 'MultipleChoice'
}

export interface Question {
    uuid: string;
    caption: string;
    type: QuestionType;
}

export interface QuestionSlider extends Question {
    options: QuestionSliderOption[];
}

export interface QuestionSliderOption {
    uuid: string;
    caption: string;
    score: number;
}

export interface QuestionMultipleChoice extends Question {
    options: QuestionMultipleChoiceOption[];
}

export interface QuestionMultipleChoiceOption {
    uuid: string;
    caption: string;
    score: number;
    isTextInput: boolean;
}

export interface Result {
    data: SessionStorageResult;
    emailAddress?: string;
    firstName?: string;
    lastName?: string;
}

export interface SessionStorageResult {
    [uuid: string]: string | null | {[uuid: string]: boolean | null | string };
}


