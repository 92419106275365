import { Injectable } from '@angular/core';
import {
    Survey,
    PageItemType,
    QuestionMultipleChoice,
    QuestionType,
    PageItemQuestion,
    Question,
    QuestionSlider,
    Result,
    SessionStorageResult,
    Role,
    } from '../models/models';
import { Observable, of, Observer, throwError, empty } from 'rxjs';
import { ApiService } from './api.service';
import { map, concat, flatMap, catchError } from 'rxjs/operators';

const CURRENT_SURVEY_UUID = 'currentSurveyUuid';
const RESULT_DATA = 'resultData';

@Injectable()
export class SurveyService {
    private mockSurvey: Survey;
    private mockSurvies: Survey[];

    constructor(
            private readonly api: ApiService) {
        this.createMockSurvey();
        this.createMockSurvies();
    }

    /**
     * Gets an array of simplified Survey objects with only a UUID and Name property and Role object.
     * This must be called for the homepage route/module with a resolver to get all the available surveys.
     */
    public getSurvies(): Observable<Survey[]> {
        return of(this.mockSurvies);
        // return this.api.getSurvies();
    }

    /**
     * Gets a full Survey object based on the UUID of the survey.
     * This must be called for the survey route/module with a resolver to get the survey.
     * @param uuid The UUID of the survey.
     */
    public getSurvey(uuid: string): Observable<Survey> {
        return of(this.mockSurvey);
        // return this.api.getSurvey(uuid);
    }

    // /**
    //  * Gets the current survey from the API based on the surveyUuid which is saved in localStorage
    //  * Also clears localStorage if something is wrong like no currentSurveyUuid found, or API can't find survey.
    //  *
    //  * This observable must immediatly be called in a resolver when the application starts to check if we are currently doing a survey.
    //  * This is neccesary for redirecting the user to the correct page when starting the applicaiton.
    //  *
    //  * When this observable returns a survey:
    //  * It must start another resolver which calls the getCurrentResultData function for the already given information.
    //  * When this observable throws:
    //  * Ignore the error and let the application load normally.
    //  */
    // public getCurrentSurvey(): Observable<Survey> {
    //     return Observable.create((observer: Observer<string>) => {
    //         // Get current survey UUID
    //         const surveyUuid = localStorage.getItem(CURRENT_SURVEY_UUID);
    //         if (surveyUuid) {
    //             // Set item on observer so next observable will be triggered.
    //             observer.next(surveyUuid);
    //             // Tell to complete the stream since we only expect a single item.
    //             observer.complete();
    //         } else {
    //             // Clear all data if not found, since key could exits and should not exist in the first place.
    //             this.clearStorage();
    //             // Throw error in observable so chain will end here.
    //             observer.error('Current survey is not set!');
    //         }
    //     }).pipe(
    //         // Chain to the getSurvey observable to get the current survey
    //         flatMap((surveyUuid: string) => this.getSurvey(surveyUuid)),
    //         catchError(error => {
    //             // Catch error and first clear local survey data if not found since surveyUuid could be changed/deleted.
    //             // Thus all the data is not valid anymore.
    //             // TODO: maybe don't clear storage when it's just an internal server error?
    //             this.clearStorage();
    //             // Rethrow error so it can be handled further in the application.
    //             return throwError(error);
    //         })
    //     );
    // }

    // /**
    //  * When a user selects a survey, it should immediatly set the current surveyUuid in this service.
    //  * This is needed so when a user closes or refreshes the application, it will redirect to the users' last selected survey.
    //  * @param uuid The uuid of the survey the user has selected.
    //  */
    // public setCurrentSurveyUuid(uuid: string) {
    //     localStorage.setItem(CURRENT_SURVEY_UUID, uuid);
    // }

    /**
     * Gets the result data from the localStorage
     * Also clears localStorage if something is wrong like no data found.
     *
     * This observable must immediatly be called after the resolver for the getCurrentSurvey completes succesfully.
     * This is neccesary for filling out the users' his already given answers.
     *
     * When this observable returns a result:
     * It must start another resolver which calls the getCurrentResultData function for the already given information.
     * It must check if all the questionUuids exist inside the survey retrieved earlier and resolve if true, reject if false.
     * When this observable throws:
     * Ignore the error and let the application load normally without any redirection.
     *
     * @param surveyUuid The survey to get the results from.
     */
    public getResultData(surveyUuid: string): Observable<SessionStorageResult> {
        return Observable.create((observer: Observer<SessionStorageResult>) => {
            const surveyData = sessionStorage.getItem(`${RESULT_DATA}_${surveyUuid}`);
            if (surveyData) {
                const deserializedSurveyData = JSON.parse(surveyData);
                observer.next(deserializedSurveyData);
                observer.complete();
            } else {
                observer.error('Curreny survey data is not set!');
            }
        });
    }

    /**
     * TODO: Determine at when this should be called.
     * @param surveyUuid The survey which the data must be saved.
     * @param data The results of the survey (from all pages!) in JSON.
     */
    public setResultData(surveyUuid: string, data: SessionStorageResult): Observable<string> {
        // Try to get previous results based on survey and session uuid.
        return this.getResultData(surveyUuid)
            .pipe(
                catchError((err: any, observable: Observable<SessionStorageResult>) => {
                    const emptyData: SessionStorageResult = {  };
                    return of(emptyData);
                }),
                flatMap((currentData: SessionStorageResult) => {
                    Object.keys(data).forEach(x => {
                        currentData[x] = data[x];
                    });
                    sessionStorage.setItem(`${RESULT_DATA}_${surveyUuid}`, JSON.stringify(currentData));
                    return sessionStorage;
                })
            );
    }

    /**
     * This function must be called when the users clicks the next button during a survey.
     * This will send the formData to the server without any personalized data.
     * @param data The formData of the survey (from all pages!) in JSON.
     */
    public sendAnonymousResult(surveyUuid: string): Observable<void> {
        const result: Result = {
            data: null,
        };
        // const result: Result = {
        //     data: this.getResultData(surveyUuid),
        // };
        console.log(result);
        return this.api.postResult(result).pipe(map(x => { return; }));
    }

    /**
     * This function must be called when the users has entered his information on the result page and has submitted this.
     * This will send the formData within a Result object with personalized data.
     * @param result The formData of the survey (from all pages!) in JSON inside a object with personalized data.
     */
    public sendPersonalResult(result: Result): Observable<void> {
        console.log(result);
        return of();
        // return this.api.postResult(result).pipe(map(x => { return; }));
    }

    /**
     * This function should be called when someone has finished a survey or when something went wrong using
     * user data inside this service.
     */
    public clearStorage(): void {
        // If some localStorage items need to be maintained, remove them seperetely instead of just clearing everything.
        sessionStorage.clear();
    }

    // -------------- TODO: Remove mock data in future below ---------------

    private createPageQuestion(question: Question): PageItemQuestion {
        const pageItemQuestion: PageItemQuestion = {
            type: PageItemType.Question,
            question: question
        };
        return pageItemQuestion;
    }

    private createMockSurvies(): void {
        const mockSurvies: Survey[] = [
            {
                uuid: '1',
                name: 'Windows Server',
                role: Role.Administrator
            },
            {
                uuid: '1',
                name: 'SQL Server',
                role: Role.Administrator
            },
            {
                uuid: '1',
                name: 'Cloud / Azure',
                role: Role.Administrator
            },
            {
                uuid: '1',
                name: '.NET',
                role: Role.Developer
            },
            {
                uuid: '1',
                name: 'Java',
                role: Role.Developer
            },
            {
                uuid: '1',
                name: 'Webstock',
                role: Role.Developer
            }
        ];
        this.mockSurvies = mockSurvies;
    }

    private createMockSurvey(): void {
        const questionJarenWerkervaring: QuestionSlider = {
            uuid: 'UUID1',
            type: QuestionType.Slider,
            caption: 'Aantal jaren werkervaring',
            options: [
                { uuid: '78384237', caption: '0-1', score: 1 },
                { uuid: '75463434', caption: '2-5', score: 1 },
                { uuid: '88956334', caption: '6 of meer', score: 1 }
            ]
        };

        const questionTeamSize: QuestionSlider = {
            uuid: 'UUID2',
            type: QuestionType.Slider,
            caption: 'Werkt u in teamverband of individueel?/Hoe groot is het team waarin u werkzaam bent?',
            options: [
                { uuid: '53542354', caption: 'alleen', score: 1 },
                { uuid: '46547354', caption: '1-3 collega\'s', score: 1 },
                { uuid: '02389534', caption: '4 of meer', score: 1 }
            ]
        };

        const questionUpToDate: QuestionMultipleChoice = {
            uuid: 'UUID3',
            type: QuestionType.MultipleChoice,
            caption: 'Hoe houd jij jezelf up-to-date?',
            options: [
                { uuid: '3654664', caption: 'Events', score: 1, isTextInput: false },
                { uuid: '9349394', caption: 'Training', score: 1, isTextInput: false },
                { uuid: '8913784', caption: 'Seminar', score: 1, isTextInput: false },
                { uuid: '8095489', caption: 'Intern overleg', score: 1, isTextInput: false },
                { uuid: '7823789', caption: 'Anders, namelijk', score: 1, isTextInput: true },
            ]
        };

        const questionDotNetTraining: QuestionSlider = {
            uuid: 'UUID4',
            type: QuestionType.Slider,
            caption: 'Heeft u een Microsoft .NET training gevolgd. Denk aan C#, ASP.net, Azure',
            options: [
                { uuid: '6546546', caption: 'nee', score: 1 },
                { uuid: '8634523', caption: 'ja 1', score: 1 },
                { uuid: '3446434', caption: 'ja 2 of meer', score: 1 }]
        };

        const questionDotNetCertificates: QuestionSlider = {
            uuid: 'UUID5',
            type: QuestionType.Slider,
            caption: 'Heeft u Microsoft .Net certificeringen',
            options: [
                { uuid: '3654664', caption: 'nee', score: 1 },
                { uuid: '7657655', caption: 'ja 1', score: 1 },
                { uuid: '5757565', caption: 'ja 2 of meer', score: 1 }]
        };

        const questionOthetTrainingsCertificates: QuestionMultipleChoice = {
            uuid: 'UUID6',
            type: QuestionType.MultipleChoice,
            caption: 'Heb je andere developmenttrainingen gevolgd/certificeringen. Zo ja welke? Denk aan HTML5, CSS3, Python, Angular.',
            options: [
                { uuid: '3535353', caption: 'HTML5', score: 1, isTextInput: false },
                { uuid: '6545445', caption: 'CSS3', score: 1, isTextInput: false },
                { uuid: '4546462', caption: 'Python', score: 1, isTextInput: false },
                { uuid: '3554343', caption: 'Angular', score: 1, isTextInput: false },
                { uuid: '3546544', caption: 'Anders, namelijk', score: 1, isTextInput: true },
            ]
        };

        this.mockSurvey = {
            role: Role.Developer,
            uuid: '1',
            name: 'SQL Server',
            pages: [
                {
                    uuid: '3443434',
                    step: 1,
                    name: 'Categorie Algemeen',
                    items: [
                        this.createPageQuestion(questionJarenWerkervaring),
                        this.createPageQuestion(questionTeamSize),
                        this.createPageQuestion(questionUpToDate)
                    ]
                },
                {
                    uuid: '3364545',
                    step: 2,
                    name: 'Categorie Vakgebied',
                    items: [
                        this.createPageQuestion(questionDotNetTraining),
                        this.createPageQuestion(questionDotNetCertificates),
                        this.createPageQuestion(questionOthetTrainingsCertificates)
                    ]
                }
            ]
        };
    }
}
