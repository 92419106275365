import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Survey, Result } from '../models/models';

@Injectable()
export class ApiService {

    private readonly apiRoot: string;

    constructor(
            private readonly configService: ConfigService,
            private readonly http: HttpClient) {
        // this.apiRoot = this.configService.getApiUri();
    }

    public getSurvies(): Observable<Survey[]> {
        return this.http.get<Survey[]>(`${this.apiRoot}/survey/`);
    }

    public getSurvey(uuid: string): Observable<Survey> {
        return this.http.get<Survey>(`${this.apiRoot}/survey/${uuid}/`);
    }

    public postResult(result: Result): Observable<object> {
        return this.http.post(`${this.apiRoot}/result/`, result);
    }
}
