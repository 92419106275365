import { Component, AfterViewInit, ComponentRef, ElementRef } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements AfterViewInit {
  title = 'app';

  ngAfterViewInit() {
    $('.loader').hide();
    setTimeout(() => {
      $('body').addClass('animate_top_bottom');
      $('header').addClass('animate_logo');
      $('.overlay').addClass('animate_overlay');
    }, 500);

    $(window).on('resize', function () {
      $('.page-wrapper').addClass('no_animations');
      $('header').addClass('no_animations');
    });

    /* For iOS touch hover effect */
    document.addEventListener('touchstart', function () { }, false);
  }
}
