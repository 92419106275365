import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: './homepage/homepage.module#HomepageModule'
    },
    {
        path: 'selection',
        loadChildren: './selection/selection.module#SelectionModule'
    },
    {
        path: 'survey',
        loadChildren: './survey/survey.module#SurveyModule'
    },
    {
        path: 'result',
        loadChildren: './result/result.module#ResultModule'
    },
    {
        path: '**',
        loadChildren: './error/error.module#ErrorModule'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule]
})

export class AppRoutingModule {}
